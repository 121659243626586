import(/* webpackMode: "eager" */ "/var/www/next-creator/app/[lang]/icon.png");
import(/* webpackMode: "eager" */ "/var/www/next-creator/app/[lang]/page.module.scss");
import(/* webpackMode: "eager" */ "/var/www/next-creator/app/[lang]/post-card-lazy.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/analytics.script.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/auth-button.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/footer.module.scss");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/article-list.module.scss");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/footer.script.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/header.module.scss");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/container.module.scss");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/category-list.module.scss");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/header.script.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/sidebar.module.scss");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/small-parts/lang-change.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/small-parts/switch-theme-button.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/use-client/advertisement/google-adsense.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/use-client/categories-dropdown.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/use-client/contactform.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/use-client/heading-table.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/components/use-client/products.dropdown.tsx");
import(/* webpackMode: "eager" */ "/var/www/next-creator/locales/flags-svg/jp.svg");
import(/* webpackMode: "eager" */ "/var/www/next-creator/locales/flags-svg/us.svg");
import(/* webpackMode: "eager" */ "/var/www/next-creator/node_modules/.pnpm/@fortawesome+fontawesome-svg-core@6.5.1/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
import(/* webpackMode: "eager" */ "/var/www/next-creator/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0_sass@1.70.0/node_modules/next/dist/client/image-component.js");
import(/* webpackMode: "eager" */ "/var/www/next-creator/node_modules/.pnpm/next@14.1.0_react-dom@18.2.0_react@18.2.0_sass@1.70.0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/var/www/next-creator/public/static-img/no-image.svg")